export default {
    findParent(el, tagName) {
        tagName = tagName.toLowerCase();

        while (el && el.parentNode) {
            el = el.parentNode;
            if (el.tagName && el.tagName.toLowerCase() === tagName) {
                return el;
            }
        }
        return null;
    },
    getElementHeight(object) {
        if (!object)
            return 0;

        let height = 0;
        let array = Array.prototype.slice.call(object.childNodes);
        array.forEach(function (element) {
            height += element.offsetHeight;
        });
        return height;
    },
    positionY() {
        return window.pageYOffset || document.documentElement.scrollTop;
    },
    isTriggerY() {
        let body = document.body, html = document.documentElement;

        let height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        let y = Math.round(this.positionY() + window.innerHeight);
        //let h = Math.round(window.innerHeight);
        let rif = Math.round(height * 98 / 100);
        return y > rif;
    },
    getPosition(object) {
        if (!object) {
            return {
                x: 0,
                y: 0
            }
        } else {
            let left = 0;
            let top = 0;
            if (object.offsetParent) {
                left = object.offsetLeft
                top = object.offsetTop
                while ((object = object.offsetParent)) {
                    left += object.offsetLeft;
                    top += object.offsetTop;
                }
            }

            return {
                x: left - 1,
                y: top
            };
        }
    },
    isOdd(number) {
        return number % 2;
    },
    loadPiexif() {
        var e = {};

        function p(e) {
            return M(">" + f("B", e.length), e)
        }

        function y(e) {
            return M(">" + f("H", e.length), e)
        }

        function S(e) {
            return M(">" + f("L", e.length), e)
        }

        function h(e, t, a) {
            var i, n, r, o, l = "", s = "";
            if ("Byte" == t) (i = e.length) <= 4 ? s = p(e) + f("\0", 4 - i) : (s = M(">L", [a]), l = p(e)); else if ("Short" == t) (i = e.length) <= 2 ? s = y(e) + f("\0\0", 2 - i) : (s = M(">L", [a]), l = y(e)); else if ("Long" == t) (i = e.length) <= 1 ? s = S(e) : (s = M(">L", [a]), l = S(e)); else if ("Ascii" == t) 4 < (i = (n = e + "\0").length) ? (s = M(">L", [a]), l = n) : s = n + f("\0", 4 - i); else if ("Rational" == t) {
                if ("number" == typeof e[0]) i = 1, r = e[0], o = e[1], n = M(">L", [r]) + M(">L", [o]); else {
                    i = e.length, n = "";
                    for (var m = 0; m < i; m++) r = e[m][0], o = e[m][1], n += M(">L", [r]) + M(">L", [o])
                }
                s = M(">L", [a]), l = n
            } else if ("SRational" == t) {
                if ("number" == typeof e[0]) i = 1, r = e[0], o = e[1], n = M(">l", [r]) + M(">l", [o]); else for (i = e.length, n = "", m = 0; m < i; m++) r = e[m][0], o = e[m][1], n += M(">l", [r]) + M(">l", [o]);
                s = M(">L", [a]), l = n
            } else "Undefined" == t && (4 < (i = e.length) ? (s = M(">L", [a]), l = e) : s = e + f("\0", 4 - i));
            return [M(">L", [i]), s, l]
        }

        function B(e, t, a) {
            var i, n = Object.keys(e).length, r = M(">H", [n]);
            i = -1 < ["0th", "1st"].indexOf(t) ? 2 + 12 * n + 4 : 2 + 12 * n;
            var o = "", l = "";
            for (var s in e) if ("string" == typeof s && (s = parseInt(s)), !("0th" == t && -1 < [34665, 34853].indexOf(s) || "Exif" == t && 40965 == s || "1st" == t && -1 < [513, 514].indexOf(s))) {
                var m = e[s], p = M(">H", [s]), y = g[t][s].type, S = M(">H", [E[y]]);
                "number" == typeof m && (m = [m]);
                var c = h(m, y, 8 + i + a + l.length);
                o += p + S + c[0] + c[1], l += c[2]
            }
            return [r + o, l]
        }

        function s(e) {
            var t;
            if ("ÿØ" == e.slice(0, 2)) t = function (e) {
                for (var t, a = 0; a < e.length; a++) if ("ÿá" == (t = e[a]).slice(0, 2)) return t;
                return null
            }(w(e)), this.tiftag = t ? t.slice(10) : null; else if (-1 < ["II", "MM"].indexOf(e.slice(0, 2))) this.tiftag = e; else {
                if ("Exif" != e.slice(0, 4)) throw"Given file is neither JPEG nor TIFF.";
                this.tiftag = e.slice(6)
            }
        }

        if (e.remove = function (e) {
            var t = !1;
            if ("ÿØ" == e.slice(0, 2)) ; else {
                if ("data:image/jpeg;base64," != e.slice(0, 23)) throw"Given data is not jpeg.";
                e = m(e.split(",")[1]), t = !0
            }
            var a = w(e);
            if ("ÿá" == a[1].slice(0, 2)) a = [a[0]].concat(a.slice(2)); else {
                if ("ÿá" != a[2].slice(0, 2)) throw"Exif not found.";
                a = a.slice(0, 2).concat(a.slice(3))
            }
            var i = a.join("");
            return t && (i = "data:image/jpeg;base64," + l(i)), i
        }, e.insert = function (e, t) {
            var a = !1;
            if ("Exif\0\0" != e.slice(0, 6)) throw"Given data is not exif.";
            if ("ÿØ" == t.slice(0, 2)) ; else {
                if ("data:image/jpeg;base64," != t.slice(0, 23)) throw"Given data is not jpeg.";
                t = m(t.split(",")[1]), a = !0
            }
            var i, n, r = "ÿá" + M(">H", [e.length + 2]) + e,
                o = (i = w(t), n = r, "ÿà" == i[1].slice(0, 2) && "ÿá" == i[2].slice(0, 2) ? i = n ? ["ÿØ", i[2] = n].concat(i.slice(2)) : null == n ? i.slice(0, 2).concat(i.slice(3)) : i.slice(0).concat(i.slice(2)) : "ÿà" == i[1].slice(0, 2) ? n && (i[1] = n) : "ÿá" == i[1].slice(0, 2) ? n ? i[1] = n : null == n && (i = i.slice(0).concat(i.slice(2))) : n && (i = [i[0], n].concat(i.slice(1))), i.join(""));
            return a && (o = "data:image/jpeg;base64," + l(o)), o
        }, e.load = function (e) {
            var t;
            if ("string" != typeof e) throw"'load' gots invalid type argument.";
            if ("ÿØ" == e.slice(0, 2)) t = e; else if ("data:image/jpeg;base64," == e.slice(0, 23)) t = m(e.split(",")[1]); else {
                if ("Exif" != e.slice(0, 4)) throw"'load' gots invalid file data.";
                t = e.slice(6)
            }
            var a = {"0th": {}, Exif: {}, GPS: {}, Interop: {}, "1st": {}, thumbnail: null}, i = new s(t);
            if (null === i.tiftag) return a;
            "II" == i.tiftag.slice(0, 2) ? i.endian_mark = "<" : i.endian_mark = ">";
            var n = c(i.endian_mark + "L", i.tiftag.slice(4, 8))[0];
            a["0th"] = i.get_ifd(n, "0th");
            var r = a["0th"].first_ifd_pointer;
            if (delete a["0th"].first_ifd_pointer, 34665 in a["0th"] && (n = a["0th"][34665], a.Exif = i.get_ifd(n, "Exif")), 34853 in a["0th"] && (n = a["0th"][34853], a.GPS = i.get_ifd(n, "GPS")), 40965 in a.Exif && (n = a.Exif[40965], a.Interop = i.get_ifd(n, "Interop")), "\0\0\0\0" != r && (n = c(i.endian_mark + "L", r)[0], a["1st"] = i.get_ifd(n, "1st"), 513 in a["1st"] && 514 in a["1st"])) {
                var o = a["1st"][513] + a["1st"][514], l = i.tiftag.slice(a["1st"][513], o);
                a.thumbnail = l
            }
            return a
        }, e.dump = function (e) {
            var t, a, i, n, r, o, l = (o = e, JSON.parse(JSON.stringify(o))), s = !1, m = !1, p = !1, y = !1;
            t = "0th" in l ? l["0th"] : {}, ("Exif" in l && Object.keys(l.Exif).length || "Interop" in l && Object.keys(l.Interop).length) && (t[34665] = 1, s = !0, a = l.Exif, "Interop" in l && Object.keys(l.Interop).length && (a[40965] = 1, p = !0, i = l.Interop)), "GPS" in l && Object.keys(l.GPS).length && (t[34853] = 1, m = !0, n = l.GPS), "1st" in l && "thumbnail" in l && null != l.thumbnail && (y = !0, l["1st"][513] = 1, l["1st"][514] = 1, r = l["1st"]);
            var S, c, h, f = B(t, "0th", 0), g = f[0].length + 12 * s + 12 * m + 4 + f[1].length, u = "", d = 0,
                P = "", C = 0, R = "", L = 0, A = "";
            if (s && (d = (S = B(a, "Exif", g))[0].length + 12 * p + S[1].length), m && (C = (P = B(n, "GPS", g + d).join("")).length), p && (L = (R = B(i, "Interop", g + d + C).join("")).length), y && (c = B(r, "1st", g + d + C + L), 64e3 < (h = function () {
                for (var e = w(l.thumbnail); "ÿà" <= e[1].slice(0, 2) && e[1].slice(0, 2) <= "ÿï";) e = [e[0]].concat(e.slice(2));
                return e.join("")
            }()).length)) throw"Given thumbnail is too large. max 64kB";
            var G = "", D = "", I = "", v = "\0\0\0\0";
            if (s) {
                var b = M(">L", [x = 8 + g]);
                G = M(">H", [34665]) + M(">H", [E.Long]) + M(">L", [1]) + b
            }
            if (m && (b = M(">L", [x = 8 + g + d]), D = M(">H", [34853]) + M(">H", [E.Long]) + M(">L", [1]) + b), p && (b = M(">L", [x = 8 + g + d + C]), I = M(">H", [40965]) + M(">H", [E.Long]) + M(">L", [1]) + b), y) {
                var x;
                v = M(">L", [x = 8 + g + d + C + L]);
                var T = "\0\0\0\0" + M(">L", [x + c[0].length + 24 + 4 + c[1].length]),
                    k = "\0\0\0\0" + M(">L", [h.length]);
                A = c[0] + T + k + "\0\0\0\0" + c[1] + h
            }
            var F = f[0] + G + D + v + f[1];
            return s && (u = S[0] + I + S[1]), "Exif\0\0MM\0*\0\0\0\b" + F + u + P + R + A
        }, s.prototype = {
            get_ifd: function (e, t) {
                var a, i = {}, n = c(this.endian_mark + "H", this.tiftag.slice(e, e + 2))[0], r = e + 2;
                a = -1 < ["0th", "1st"].indexOf(t) ? "Image" : t;
                for (var o = 0; o < n; o++) {
                    e = r + 12 * o;
                    var l = c(this.endian_mark + "H", this.tiftag.slice(e, e + 2))[0],
                        s = [c(this.endian_mark + "H", this.tiftag.slice(e + 2, e + 4))[0], c(this.endian_mark + "L", this.tiftag.slice(e + 4, e + 8))[0], this.tiftag.slice(e + 8, e + 12)];
                    l in g[a] && (i[l] = this.convert_value(s))
                }
                return "0th" == t && (e = r + 12 * n, i.first_ifd_pointer = this.tiftag.slice(e, e + 4)), i
            }, convert_value: function (e) {
                var t, a = null, i = e[0], n = e[1], r = e[2];
                if (1 == i) a = 4 < n ? (t = c(this.endian_mark + "L", r)[0], c(this.endian_mark + f("B", n), this.tiftag.slice(t, t + n))) : c(this.endian_mark + f("B", n), r.slice(0, n)); else if (2 == i) a = 4 < n ? (t = c(this.endian_mark + "L", r)[0], this.tiftag.slice(t, t + n - 1)) : r.slice(0, n - 1); else if (3 == i) a = 2 < n ? (t = c(this.endian_mark + "L", r)[0], c(this.endian_mark + f("H", n), this.tiftag.slice(t, t + 2 * n))) : c(this.endian_mark + f("H", n), r.slice(0, 2 * n)); else if (4 == i) a = 1 < n ? (t = c(this.endian_mark + "L", r)[0], c(this.endian_mark + f("L", n), this.tiftag.slice(t, t + 4 * n))) : c(this.endian_mark + f("L", n), r); else if (5 == i) if (t = c(this.endian_mark + "L", r)[0], 1 < n) {
                    a = [];
                    for (var o = 0; o < n; o++) a.push([c(this.endian_mark + "L", this.tiftag.slice(t + 8 * o, t + 4 + 8 * o))[0], c(this.endian_mark + "L", this.tiftag.slice(t + 4 + 8 * o, t + 8 + 8 * o))[0]])
                } else a = [c(this.endian_mark + "L", this.tiftag.slice(t, t + 4))[0], c(this.endian_mark + "L", this.tiftag.slice(t + 4, t + 8))[0]]; else if (7 == i) a = 4 < n ? (t = c(this.endian_mark + "L", r)[0], this.tiftag.slice(t, t + n)) : r.slice(0, n); else {
                    if (10 != i) throw"Exif might be wrong. Got incorrect value type to decode. type:" + i;
                    if (t = c(this.endian_mark + "L", r)[0], 1 < n) for (a = [], o = 0; o < n; o++) a.push([c(this.endian_mark + "l", this.tiftag.slice(t + 8 * o, t + 4 + 8 * o))[0], c(this.endian_mark + "l", this.tiftag.slice(t + 4 + 8 * o, t + 8 + 8 * o))[0]]); else a = [c(this.endian_mark + "l", this.tiftag.slice(t, t + 4))[0], c(this.endian_mark + "l", this.tiftag.slice(t + 4, t + 8))[0]]
                }
                return a instanceof Array && 1 == a.length ? a[0] : a
            }
        }, void 0 === l) var l = function (e) {
            for (var t, a, i, n, r, o, l, s = "", m = 0, p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="; m < e.length;) n = (t = e.charCodeAt(m++)) >> 2, r = (3 & t) << 4 | (a = e.charCodeAt(m++)) >> 4, o = (15 & a) << 2 | (i = e.charCodeAt(m++)) >> 6, l = 63 & i, isNaN(a) ? o = l = 64 : isNaN(i) && (l = 64), s = s + p.charAt(n) + p.charAt(r) + p.charAt(o) + p.charAt(l);
            return s
        };
        if (void 0 === m) var m = function (e) {
            var t, a, i, n, r, o, l = "", s = 0,
                m = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
            for (e = e.replace(/[^A-Za-z0-9+/=]/g, ""); s < e.length;) t = m.indexOf(e.charAt(s++)) << 2 | (n = m.indexOf(e.charAt(s++))) >> 4, a = (15 & n) << 4 | (r = m.indexOf(e.charAt(s++))) >> 2, i = (3 & r) << 6 | (o = m.indexOf(e.charAt(s++))), l += String.fromCharCode(t), 64 != r && (l += String.fromCharCode(a)), 64 != o && (l += String.fromCharCode(i));
            return l
        };

        function M(e, t) {
            if (!(t instanceof Array)) throw"'pack' error. Got invalid type argument.";
            if (e.length - 1 != t.length) throw"'pack' error. " + (e.length - 1) + " marks, " + t.length + " elements.";
            var a;
            if ("<" == e[0]) a = !0; else {
                if (">" != e[0]) throw"";
                a = !1
            }
            for (var i = "", n = 1, r = null, o = null, l = null; (o = e[n]);) {
                if ("b" == o.toLowerCase()) {
                    if (r = t[n - 1], "b" == o && r < 0 && (r += 256), 255 < r || r < 0) throw"'pack' error.";
                    l = String.fromCharCode(r)
                } else if ("H" == o) {
                    if (65535 < (r = t[n - 1]) || r < 0) throw"'pack' error.";
                    l = String.fromCharCode(Math.floor(r % 65536 / 256)) + String.fromCharCode(r % 256), a && (l = l.split("").reverse().join(""))
                } else {
                    if ("l" != o.toLowerCase()) throw"'pack' error.";
                    if (r = t[n - 1], "l" == o && r < 0 && (r += 4294967296), 4294967295 < r || r < 0) throw"'pack' error.";
                    l = String.fromCharCode(Math.floor(r / 16777216)) + String.fromCharCode(Math.floor(r % 16777216 / 65536)) + String.fromCharCode(Math.floor(r % 65536 / 256)) + String.fromCharCode(r % 256), a && (l = l.split("").reverse().join(""))
                }
                i += l, n += 1
            }
            return i
        }

        function c(e, t) {
            if ("string" != typeof t) throw"'unpack' error. Got invalid type argument.";
            for (var a, i = 0, n = 1; n < e.length; n++) if ("b" == e[n].toLowerCase()) i += 1; else if ("h" == e[n].toLowerCase()) i += 2; else {
                if ("l" != e[n].toLowerCase()) throw"'unpack' error. Got invalid mark.";
                i += 4
            }
            if (i != t.length) throw"'unpack' error. Mismatch between symbol and string length. " + i + ":" + t.length;
            if ("<" == e[0]) a = !0; else {
                if (">" != e[0]) throw"'unpack' error.";
                a = !1
            }
            for (var r = [], o = 0, l = 1, s = null, m = null, p = null, y = ""; (m = e[l]);) {
                if ("b" == m.toLowerCase()) p = 1, s = (y = t.slice(o, o + p)).charCodeAt(0), "b" == m && 128 <= s && (s -= 256); else if ("H" == m) p = 2, y = t.slice(o, o + p), a && (y = y.split("").reverse().join("")), s = 256 * y.charCodeAt(0) + y.charCodeAt(1); else {
                    if ("l" != m.toLowerCase()) throw"'unpack' error. " + m;
                    p = 4, y = t.slice(o, o + p), a && (y = y.split("").reverse().join("")), s = 16777216 * y.charCodeAt(0) + 65536 * y.charCodeAt(1) + 256 * y.charCodeAt(2) + y.charCodeAt(3), "l" == m && 2147483648 <= s && (s -= 4294967296)
                }
                r.push(s), o += p, l += 1
            }
            return r
        }

        function f(e, t) {
            for (var a = "", i = 0; i < t; i++) a += e;
            return a
        }

        function w(e) {
            if ("ÿØ" != e.slice(0, 2)) throw"Given data isn't JPEG.";
            for (var t = 2, a = ["ÿØ"]; ;) {
                if ("ÿÚ" == e.slice(t, t + 2)) {
                    a.push(e.slice(t));
                    break
                }
                var i = t + c(">H", e.slice(t + 2, t + 4))[0] + 2;
                if (a.push(e.slice(t, i)), (t = i) >= e.length) throw"Wrong JPEG data."
            }
            return a
        }

        var E = {Byte: 1, Ascii: 2, Short: 3, Long: 4, Rational: 5, Undefined: 7, SLong: 9, SRational: 10}, g = {
            Image: {
                11: {name: "ProcessingSoftware", type: "Ascii"},
                254: {name: "NewSubfileType", type: "Long"},
                255: {name: "SubfileType", type: "Short"},
                256: {name: "ImageWidth", type: "Long"},
                257: {name: "ImageLength", type: "Long"},
                258: {name: "BitsPerSample", type: "Short"},
                259: {name: "Compression", type: "Short"},
                262: {name: "PhotometricInterpretation", type: "Short"},
                263: {name: "Threshholding", type: "Short"},
                264: {name: "CellWidth", type: "Short"},
                265: {name: "CellLength", type: "Short"},
                266: {name: "FillOrder", type: "Short"},
                269: {name: "DocumentName", type: "Ascii"},
                270: {name: "ImageDescription", type: "Ascii"},
                271: {name: "Make", type: "Ascii"},
                272: {name: "Model", type: "Ascii"},
                273: {name: "StripOffsets", type: "Long"},
                274: {name: "Orientation", type: "Short"},
                277: {name: "SamplesPerPixel", type: "Short"},
                278: {name: "RowsPerStrip", type: "Long"},
                279: {name: "StripByteCounts", type: "Long"},
                282: {name: "XResolution", type: "Rational"},
                283: {name: "YResolution", type: "Rational"},
                284: {name: "PlanarConfiguration", type: "Short"},
                290: {name: "GrayResponseUnit", type: "Short"},
                291: {name: "GrayResponseCurve", type: "Short"},
                292: {name: "T4Options", type: "Long"},
                293: {name: "T6Options", type: "Long"},
                296: {name: "ResolutionUnit", type: "Short"},
                301: {name: "TransferFunction", type: "Short"},
                305: {name: "Software", type: "Ascii"},
                306: {name: "DateTime", type: "Ascii"},
                315: {name: "Artist", type: "Ascii"},
                316: {name: "HostComputer", type: "Ascii"},
                317: {name: "Predictor", type: "Short"},
                318: {name: "WhitePoint", type: "Rational"},
                319: {name: "PrimaryChromaticities", type: "Rational"},
                320: {name: "ColorMap", type: "Short"},
                321: {name: "HalftoneHints", type: "Short"},
                322: {name: "TileWidth", type: "Short"},
                323: {name: "TileLength", type: "Short"},
                324: {name: "TileOffsets", type: "Short"},
                325: {name: "TileByteCounts", type: "Short"},
                330: {name: "SubIFDs", type: "Long"},
                332: {name: "InkSet", type: "Short"},
                333: {name: "InkNames", type: "Ascii"},
                334: {name: "NumberOfInks", type: "Short"},
                336: {name: "DotRange", type: "Byte"},
                337: {name: "TargetPrinter", type: "Ascii"},
                338: {name: "ExtraSamples", type: "Short"},
                339: {name: "SampleFormat", type: "Short"},
                340: {name: "SMinSampleValue", type: "Short"},
                341: {name: "SMaxSampleValue", type: "Short"},
                342: {name: "TransferRange", type: "Short"},
                343: {name: "ClipPath", type: "Byte"},
                344: {name: "XClipPathUnits", type: "Long"},
                345: {name: "YClipPathUnits", type: "Long"},
                346: {name: "Indexed", type: "Short"},
                347: {name: "JPEGTables", type: "Undefined"},
                351: {name: "OPIProxy", type: "Short"},
                512: {name: "JPEGProc", type: "Long"},
                513: {name: "JPEGInterchangeFormat", type: "Long"},
                514: {name: "JPEGInterchangeFormatLength", type: "Long"},
                515: {name: "JPEGRestartInterval", type: "Short"},
                517: {name: "JPEGLosslessPredictors", type: "Short"},
                518: {name: "JPEGPointTransforms", type: "Short"},
                519: {name: "JPEGQTables", type: "Long"},
                520: {name: "JPEGDCTables", type: "Long"},
                521: {name: "JPEGACTables", type: "Long"},
                529: {name: "YCbCrCoefficients", type: "Rational"},
                530: {name: "YCbCrSubSampling", type: "Short"},
                531: {name: "YCbCrPositioning", type: "Short"},
                532: {name: "ReferenceBlackWhite", type: "Rational"},
                700: {name: "XMLPacket", type: "Byte"},
                18246: {name: "Rating", type: "Short"},
                18249: {name: "RatingPercent", type: "Short"},
                32781: {name: "ImageID", type: "Ascii"},
                33421: {name: "CFARepeatPatternDim", type: "Short"},
                33422: {name: "CFAPattern", type: "Byte"},
                33423: {name: "BatteryLevel", type: "Rational"},
                33432: {name: "Copyright", type: "Ascii"},
                33434: {name: "ExposureTime", type: "Rational"},
                34377: {name: "ImageResources", type: "Byte"},
                34665: {name: "ExifTag", type: "Long"},
                34675: {name: "InterColorProfile", type: "Undefined"},
                34853: {name: "GPSTag", type: "Long"},
                34857: {name: "Interlace", type: "Short"},
                34858: {name: "TimeZoneOffset", type: "Long"},
                34859: {name: "SelfTimerMode", type: "Short"},
                37387: {name: "FlashEnergy", type: "Rational"},
                37388: {name: "SpatialFrequencyResponse", type: "Undefined"},
                37389: {name: "Noise", type: "Undefined"},
                37390: {name: "FocalPlaneXResolution", type: "Rational"},
                37391: {name: "FocalPlaneYResolution", type: "Rational"},
                37392: {name: "FocalPlaneResolutionUnit", type: "Short"},
                37393: {name: "ImageNumber", type: "Long"},
                37394: {name: "SecurityClassification", type: "Ascii"},
                37395: {name: "ImageHistory", type: "Ascii"},
                37397: {name: "ExposureIndex", type: "Rational"},
                37398: {name: "TIFFEPStandardID", type: "Byte"},
                37399: {name: "SensingMethod", type: "Short"},
                40091: {name: "XPTitle", type: "Byte"},
                40092: {name: "XPComment", type: "Byte"},
                40093: {name: "XPAuthor", type: "Byte"},
                40094: {name: "XPKeywords", type: "Byte"},
                40095: {name: "XPSubject", type: "Byte"},
                50341: {name: "PrintImageMatching", type: "Undefined"},
                50706: {name: "DNGVersion", type: "Byte"},
                50707: {name: "DNGBackwardVersion", type: "Byte"},
                50708: {name: "UniqueCameraModel", type: "Ascii"},
                50709: {name: "LocalizedCameraModel", type: "Byte"},
                50710: {name: "CFAPlaneColor", type: "Byte"},
                50711: {name: "CFALayout", type: "Short"},
                50712: {name: "LinearizationTable", type: "Short"},
                50713: {name: "BlackLevelRepeatDim", type: "Short"},
                50714: {name: "BlackLevel", type: "Rational"},
                50715: {name: "BlackLevelDeltaH", type: "SRational"},
                50716: {name: "BlackLevelDeltaV", type: "SRational"},
                50717: {name: "WhiteLevel", type: "Short"},
                50718: {name: "DefaultScale", type: "Rational"},
                50719: {name: "DefaultCropOrigin", type: "Short"},
                50720: {name: "DefaultCropSize", type: "Short"},
                50721: {name: "ColorMatrix1", type: "SRational"},
                50722: {name: "ColorMatrix2", type: "SRational"},
                50723: {name: "CameraCalibration1", type: "SRational"},
                50724: {name: "CameraCalibration2", type: "SRational"},
                50725: {name: "ReductionMatrix1", type: "SRational"},
                50726: {name: "ReductionMatrix2", type: "SRational"},
                50727: {name: "AnalogBalance", type: "Rational"},
                50728: {name: "AsShotNeutral", type: "Short"},
                50729: {name: "AsShotWhiteXY", type: "Rational"},
                50730: {name: "BaselineExposure", type: "SRational"},
                50731: {name: "BaselineNoise", type: "Rational"},
                50732: {name: "BaselineSharpness", type: "Rational"},
                50733: {name: "BayerGreenSplit", type: "Long"},
                50734: {name: "LinearResponseLimit", type: "Rational"},
                50735: {name: "CameraSerialNumber", type: "Ascii"},
                50736: {name: "LensInfo", type: "Rational"},
                50737: {name: "ChromaBlurRadius", type: "Rational"},
                50738: {name: "AntiAliasStrength", type: "Rational"},
                50739: {name: "ShadowScale", type: "SRational"},
                50740: {name: "DNGPrivateData", type: "Byte"},
                50741: {name: "MakerNoteSafety", type: "Short"},
                50778: {name: "CalibrationIlluminant1", type: "Short"},
                50779: {name: "CalibrationIlluminant2", type: "Short"},
                50780: {name: "BestQualityScale", type: "Rational"},
                50781: {name: "RawDataUniqueID", type: "Byte"},
                50827: {name: "OriginalRawFileName", type: "Byte"},
                50828: {name: "OriginalRawFileData", type: "Undefined"},
                50829: {name: "ActiveArea", type: "Short"},
                50830: {name: "MaskedAreas", type: "Short"},
                50831: {name: "AsShotICCProfile", type: "Undefined"},
                50832: {name: "AsShotPreProfileMatrix", type: "SRational"},
                50833: {name: "CurrentICCProfile", type: "Undefined"},
                50834: {name: "CurrentPreProfileMatrix", type: "SRational"},
                50879: {name: "ColorimetricReference", type: "Short"},
                50931: {name: "CameraCalibrationSignature", type: "Byte"},
                50932: {name: "ProfileCalibrationSignature", type: "Byte"},
                50934: {name: "AsShotProfileName", type: "Byte"},
                50935: {name: "NoiseReductionApplied", type: "Rational"},
                50936: {name: "ProfileName", type: "Byte"},
                50937: {name: "ProfileHueSatMapDims", type: "Long"},
                50938: {name: "ProfileHueSatMapData1", type: "Float"},
                50939: {name: "ProfileHueSatMapData2", type: "Float"},
                50940: {name: "ProfileToneCurve", type: "Float"},
                50941: {name: "ProfileEmbedPolicy", type: "Long"},
                50942: {name: "ProfileCopyright", type: "Byte"},
                50964: {name: "ForwardMatrix1", type: "SRational"},
                50965: {name: "ForwardMatrix2", type: "SRational"},
                50966: {name: "PreviewApplicationName", type: "Byte"},
                50967: {name: "PreviewApplicationVersion", type: "Byte"},
                50968: {name: "PreviewSettingsName", type: "Byte"},
                50969: {name: "PreviewSettingsDigest", type: "Byte"},
                50970: {name: "PreviewColorSpace", type: "Long"},
                50971: {name: "PreviewDateTime", type: "Ascii"},
                50972: {name: "RawImageDigest", type: "Undefined"},
                50973: {name: "OriginalRawFileDigest", type: "Undefined"},
                50974: {name: "SubTileBlockSize", type: "Long"},
                50975: {name: "RowInterleaveFactor", type: "Long"},
                50981: {name: "ProfileLookTableDims", type: "Long"},
                50982: {name: "ProfileLookTableData", type: "Float"},
                51008: {name: "OpcodeList1", type: "Undefined"},
                51009: {name: "OpcodeList2", type: "Undefined"},
                51022: {name: "OpcodeList3", type: "Undefined"}
            }, Exif: {
                33434: {name: "ExposureTime", type: "Rational"},
                33437: {name: "FNumber", type: "Rational"},
                34850: {name: "ExposureProgram", type: "Short"},
                34852: {name: "SpectralSensitivity", type: "Ascii"},
                34855: {name: "ISOSpeedRatings", type: "Short"},
                34856: {name: "OECF", type: "Undefined"},
                34864: {name: "SensitivityType", type: "Short"},
                34865: {name: "StandardOutputSensitivity", type: "Long"},
                34866: {name: "RecommendedExposureIndex", type: "Long"},
                34867: {name: "ISOSpeed", type: "Long"},
                34868: {name: "ISOSpeedLatitudeyyy", type: "Long"},
                34869: {name: "ISOSpeedLatitudezzz", type: "Long"},
                36864: {name: "ExifVersion", type: "Undefined"},
                36867: {name: "DateTimeOriginal", type: "Ascii"},
                36868: {name: "DateTimeDigitized", type: "Ascii"},
                37121: {name: "ComponentsConfiguration", type: "Undefined"},
                37122: {name: "CompressedBitsPerPixel", type: "Rational"},
                37377: {name: "ShutterSpeedValue", type: "SRational"},
                37378: {name: "ApertureValue", type: "Rational"},
                37379: {name: "BrightnessValue", type: "SRational"},
                37380: {name: "ExposureBiasValue", type: "SRational"},
                37381: {name: "MaxApertureValue", type: "Rational"},
                37382: {name: "SubjectDistance", type: "Rational"},
                37383: {name: "MeteringMode", type: "Short"},
                37384: {name: "LightSource", type: "Short"},
                37385: {name: "Flash", type: "Short"},
                37386: {name: "FocalLength", type: "Rational"},
                37396: {name: "SubjectArea", type: "Short"},
                37500: {name: "MakerNote", type: "Undefined"},
                37510: {name: "UserComment", type: "Ascii"},
                37520: {name: "SubSecTime", type: "Ascii"},
                37521: {name: "SubSecTimeOriginal", type: "Ascii"},
                37522: {name: "SubSecTimeDigitized", type: "Ascii"},
                40960: {name: "FlashpixVersion", type: "Undefined"},
                40961: {name: "ColorSpace", type: "Short"},
                40962: {name: "PixelXDimension", type: "Long"},
                40963: {name: "PixelYDimension", type: "Long"},
                40964: {name: "RelatedSoundFile", type: "Ascii"},
                40965: {name: "InteroperabilityTag", type: "Long"},
                41483: {name: "FlashEnergy", type: "Rational"},
                41484: {name: "SpatialFrequencyResponse", type: "Undefined"},
                41486: {name: "FocalPlaneXResolution", type: "Rational"},
                41487: {name: "FocalPlaneYResolution", type: "Rational"},
                41488: {name: "FocalPlaneResolutionUnit", type: "Short"},
                41492: {name: "SubjectLocation", type: "Short"},
                41493: {name: "ExposureIndex", type: "Rational"},
                41495: {name: "SensingMethod", type: "Short"},
                41728: {name: "FileSource", type: "Undefined"},
                41729: {name: "SceneType", type: "Undefined"},
                41730: {name: "CFAPattern", type: "Undefined"},
                41985: {name: "CustomRendered", type: "Short"},
                41986: {name: "ExposureMode", type: "Short"},
                41987: {name: "WhiteBalance", type: "Short"},
                41988: {name: "DigitalZoomRatio", type: "Rational"},
                41989: {name: "FocalLengthIn35mmFilm", type: "Short"},
                41990: {name: "SceneCaptureType", type: "Short"},
                41991: {name: "GainControl", type: "Short"},
                41992: {name: "Contrast", type: "Short"},
                41993: {name: "Saturation", type: "Short"},
                41994: {name: "Sharpness", type: "Short"},
                41995: {name: "DeviceSettingDescription", type: "Undefined"},
                41996: {name: "SubjectDistanceRange", type: "Short"},
                42016: {name: "ImageUniqueID", type: "Ascii"},
                42032: {name: "CameraOwnerName", type: "Ascii"},
                42033: {name: "BodySerialNumber", type: "Ascii"},
                42034: {name: "LensSpecification", type: "Rational"},
                42035: {name: "LensMake", type: "Ascii"},
                42036: {name: "LensModel", type: "Ascii"},
                42037: {name: "LensSerialNumber", type: "Ascii"},
                42240: {name: "Gamma", type: "Rational"}
            }, GPS: {
                0: {name: "GPSVersionID", type: "Byte"},
                1: {name: "GPSLatitudeRef", type: "Ascii"},
                2: {name: "GPSLatitude", type: "Rational"},
                3: {name: "GPSLongitudeRef", type: "Ascii"},
                4: {name: "GPSLongitude", type: "Rational"},
                5: {name: "GPSAltitudeRef", type: "Byte"},
                6: {name: "GPSAltitude", type: "Rational"},
                7: {name: "GPSTimeStamp", type: "Rational"},
                8: {name: "GPSSatellites", type: "Ascii"},
                9: {name: "GPSStatus", type: "Ascii"},
                10: {name: "GPSMeasureMode", type: "Ascii"},
                11: {name: "GPSDOP", type: "Rational"},
                12: {name: "GPSSpeedRef", type: "Ascii"},
                13: {name: "GPSSpeed", type: "Rational"},
                14: {name: "GPSTrackRef", type: "Ascii"},
                15: {name: "GPSTrack", type: "Rational"},
                16: {name: "GPSImgDirectionRef", type: "Ascii"},
                17: {name: "GPSImgDirection", type: "Rational"},
                18: {name: "GPSMapDatum", type: "Ascii"},
                19: {name: "GPSDestLatitudeRef", type: "Ascii"},
                20: {name: "GPSDestLatitude", type: "Rational"},
                21: {name: "GPSDestLongitudeRef", type: "Ascii"},
                22: {name: "GPSDestLongitude", type: "Rational"},
                23: {name: "GPSDestBearingRef", type: "Ascii"},
                24: {name: "GPSDestBearing", type: "Rational"},
                25: {name: "GPSDestDistanceRef", type: "Ascii"},
                26: {name: "GPSDestDistance", type: "Rational"},
                27: {name: "GPSProcessingMethod", type: "Undefined"},
                28: {name: "GPSAreaInformation", type: "Undefined"},
                29: {name: "GPSDateStamp", type: "Ascii"},
                30: {name: "GPSDifferential", type: "Short"},
                31: {name: "GPSHPositioningError", type: "Rational"}
            }, Interop: {1: {name: "InteroperabilityIndex", type: "Ascii"}}
        };
        g["0th"] = g.Image, g["1st"] = g.Image, e.TAGS = g, e.ImageIFD = {
            ProcessingSoftware: 11,
            NewSubfileType: 254,
            SubfileType: 255,
            ImageWidth: 256,
            ImageLength: 257,
            BitsPerSample: 258,
            Compression: 259,
            PhotometricInterpretation: 262,
            Threshholding: 263,
            CellWidth: 264,
            CellLength: 265,
            FillOrder: 266,
            DocumentName: 269,
            ImageDescription: 270,
            Make: 271,
            Model: 272,
            StripOffsets: 273,
            Orientation: 274,
            SamplesPerPixel: 277,
            RowsPerStrip: 278,
            StripByteCounts: 279,
            XResolution: 282,
            YResolution: 283,
            PlanarConfiguration: 284,
            GrayResponseUnit: 290,
            GrayResponseCurve: 291,
            T4Options: 292,
            T6Options: 293,
            ResolutionUnit: 296,
            TransferFunction: 301,
            Software: 305,
            DateTime: 306,
            Artist: 315,
            HostComputer: 316,
            Predictor: 317,
            WhitePoint: 318,
            PrimaryChromaticities: 319,
            ColorMap: 320,
            HalftoneHints: 321,
            TileWidth: 322,
            TileLength: 323,
            TileOffsets: 324,
            TileByteCounts: 325,
            SubIFDs: 330,
            InkSet: 332,
            InkNames: 333,
            NumberOfInks: 334,
            DotRange: 336,
            TargetPrinter: 337,
            ExtraSamples: 338,
            SampleFormat: 339,
            SMinSampleValue: 340,
            SMaxSampleValue: 341,
            TransferRange: 342,
            ClipPath: 343,
            XClipPathUnits: 344,
            YClipPathUnits: 345,
            Indexed: 346,
            JPEGTables: 347,
            OPIProxy: 351,
            JPEGProc: 512,
            JPEGInterchangeFormat: 513,
            JPEGInterchangeFormatLength: 514,
            JPEGRestartInterval: 515,
            JPEGLosslessPredictors: 517,
            JPEGPointTransforms: 518,
            JPEGQTables: 519,
            JPEGDCTables: 520,
            JPEGACTables: 521,
            YCbCrCoefficients: 529,
            YCbCrSubSampling: 530,
            YCbCrPositioning: 531,
            ReferenceBlackWhite: 532,
            XMLPacket: 700,
            Rating: 18246,
            RatingPercent: 18249,
            ImageID: 32781,
            CFARepeatPatternDim: 33421,
            CFAPattern: 33422,
            BatteryLevel: 33423,
            Copyright: 33432,
            ExposureTime: 33434,
            ImageResources: 34377,
            ExifTag: 34665,
            InterColorProfile: 34675,
            GPSTag: 34853,
            Interlace: 34857,
            TimeZoneOffset: 34858,
            SelfTimerMode: 34859,
            FlashEnergy: 37387,
            SpatialFrequencyResponse: 37388,
            Noise: 37389,
            FocalPlaneXResolution: 37390,
            FocalPlaneYResolution: 37391,
            FocalPlaneResolutionUnit: 37392,
            ImageNumber: 37393,
            SecurityClassification: 37394,
            ImageHistory: 37395,
            ExposureIndex: 37397,
            TIFFEPStandardID: 37398,
            SensingMethod: 37399,
            XPTitle: 40091,
            XPComment: 40092,
            XPAuthor: 40093,
            XPKeywords: 40094,
            XPSubject: 40095,
            PrintImageMatching: 50341,
            DNGVersion: 50706,
            DNGBackwardVersion: 50707,
            UniqueCameraModel: 50708,
            LocalizedCameraModel: 50709,
            CFAPlaneColor: 50710,
            CFALayout: 50711,
            LinearizationTable: 50712,
            BlackLevelRepeatDim: 50713,
            BlackLevel: 50714,
            BlackLevelDeltaH: 50715,
            BlackLevelDeltaV: 50716,
            WhiteLevel: 50717,
            DefaultScale: 50718,
            DefaultCropOrigin: 50719,
            DefaultCropSize: 50720,
            ColorMatrix1: 50721,
            ColorMatrix2: 50722,
            CameraCalibration1: 50723,
            CameraCalibration2: 50724,
            ReductionMatrix1: 50725,
            ReductionMatrix2: 50726,
            AnalogBalance: 50727,
            AsShotNeutral: 50728,
            AsShotWhiteXY: 50729,
            BaselineExposure: 50730,
            BaselineNoise: 50731,
            BaselineSharpness: 50732,
            BayerGreenSplit: 50733,
            LinearResponseLimit: 50734,
            CameraSerialNumber: 50735,
            LensInfo: 50736,
            ChromaBlurRadius: 50737,
            AntiAliasStrength: 50738,
            ShadowScale: 50739,
            DNGPrivateData: 50740,
            MakerNoteSafety: 50741,
            CalibrationIlluminant1: 50778,
            CalibrationIlluminant2: 50779,
            BestQualityScale: 50780,
            RawDataUniqueID: 50781,
            OriginalRawFileName: 50827,
            OriginalRawFileData: 50828,
            ActiveArea: 50829,
            MaskedAreas: 50830,
            AsShotICCProfile: 50831,
            AsShotPreProfileMatrix: 50832,
            CurrentICCProfile: 50833,
            CurrentPreProfileMatrix: 50834,
            ColorimetricReference: 50879,
            CameraCalibrationSignature: 50931,
            ProfileCalibrationSignature: 50932,
            AsShotProfileName: 50934,
            NoiseReductionApplied: 50935,
            ProfileName: 50936,
            ProfileHueSatMapDims: 50937,
            ProfileHueSatMapData1: 50938,
            ProfileHueSatMapData2: 50939,
            ProfileToneCurve: 50940,
            ProfileEmbedPolicy: 50941,
            ProfileCopyright: 50942,
            ForwardMatrix1: 50964,
            ForwardMatrix2: 50965,
            PreviewApplicationName: 50966,
            PreviewApplicationVersion: 50967,
            PreviewSettingsName: 50968,
            PreviewSettingsDigest: 50969,
            PreviewColorSpace: 50970,
            PreviewDateTime: 50971,
            RawImageDigest: 50972,
            OriginalRawFileDigest: 50973,
            SubTileBlockSize: 50974,
            RowInterleaveFactor: 50975,
            ProfileLookTableDims: 50981,
            ProfileLookTableData: 50982,
            OpcodeList1: 51008,
            OpcodeList2: 51009,
            OpcodeList3: 51022,
            NoiseProfile: 51041
        }, e.ExifIFD = {
            ExposureTime: 33434,
            FNumber: 33437,
            ExposureProgram: 34850,
            SpectralSensitivity: 34852,
            ISOSpeedRatings: 34855,
            OECF: 34856,
            SensitivityType: 34864,
            StandardOutputSensitivity: 34865,
            RecommendedExposureIndex: 34866,
            ISOSpeed: 34867,
            ISOSpeedLatitudeyyy: 34868,
            ISOSpeedLatitudezzz: 34869,
            ExifVersion: 36864,
            DateTimeOriginal: 36867,
            DateTimeDigitized: 36868,
            ComponentsConfiguration: 37121,
            CompressedBitsPerPixel: 37122,
            ShutterSpeedValue: 37377,
            ApertureValue: 37378,
            BrightnessValue: 37379,
            ExposureBiasValue: 37380,
            MaxApertureValue: 37381,
            SubjectDistance: 37382,
            MeteringMode: 37383,
            LightSource: 37384,
            Flash: 37385,
            FocalLength: 37386,
            SubjectArea: 37396,
            MakerNote: 37500,
            UserComment: 37510,
            SubSecTime: 37520,
            SubSecTimeOriginal: 37521,
            SubSecTimeDigitized: 37522,
            FlashpixVersion: 40960,
            ColorSpace: 40961,
            PixelXDimension: 40962,
            PixelYDimension: 40963,
            RelatedSoundFile: 40964,
            InteroperabilityTag: 40965,
            FlashEnergy: 41483,
            SpatialFrequencyResponse: 41484,
            FocalPlaneXResolution: 41486,
            FocalPlaneYResolution: 41487,
            FocalPlaneResolutionUnit: 41488,
            SubjectLocation: 41492,
            ExposureIndex: 41493,
            SensingMethod: 41495,
            FileSource: 41728,
            SceneType: 41729,
            CFAPattern: 41730,
            CustomRendered: 41985,
            ExposureMode: 41986,
            WhiteBalance: 41987,
            DigitalZoomRatio: 41988,
            FocalLengthIn35mmFilm: 41989,
            SceneCaptureType: 41990,
            GainControl: 41991,
            Contrast: 41992,
            Saturation: 41993,
            Sharpness: 41994,
            DeviceSettingDescription: 41995,
            SubjectDistanceRange: 41996,
            ImageUniqueID: 42016,
            CameraOwnerName: 42032,
            BodySerialNumber: 42033,
            LensSpecification: 42034,
            LensMake: 42035,
            LensModel: 42036,
            LensSerialNumber: 42037,
            Gamma: 42240
        }, e.GPSIFD = {
            GPSVersionID: 0,
            GPSLatitudeRef: 1,
            GPSLatitude: 2,
            GPSLongitudeRef: 3,
            GPSLongitude: 4,
            GPSAltitudeRef: 5,
            GPSAltitude: 6,
            GPSTimeStamp: 7,
            GPSSatellites: 8,
            GPSStatus: 9,
            GPSMeasureMode: 10,
            GPSDOP: 11,
            GPSSpeedRef: 12,
            GPSSpeed: 13,
            GPSTrackRef: 14,
            GPSTrack: 15,
            GPSImgDirectionRef: 16,
            GPSImgDirection: 17,
            GPSMapDatum: 18,
            GPSDestLatitudeRef: 19,
            GPSDestLatitude: 20,
            GPSDestLongitudeRef: 21,
            GPSDestLongitude: 22,
            GPSDestBearingRef: 23,
            GPSDestBearing: 24,
            GPSDestDistanceRef: 25,
            GPSDestDistance: 26,
            GPSProcessingMethod: 27,
            GPSAreaInformation: 28,
            GPSDateStamp: 29,
            GPSDifferential: 30,
            GPSHPositioningError: 31
        }, e.InteropIFD = {InteroperabilityIndex: 1}, "undefined" != typeof exports ? ("undefined" != typeof module && module.exports && (exports = module.exports = e), exports.piexif = e) : window.piexif = e
    },
    rotateImage(tagimg) {

        let desiredW = 1920;
        let desiredH = 1440;
        let c = document.createElement("canvas");
        let ctx = c.getContext("2d");
        ctx.mozImageSmoothingEnabled =
            ctx.webkitImageSmoothingEnabled =
                ctx.msImageSmoothingEnabled =
                    ctx.imageSmoothingEnabled = true;
        let img = tagimg;


        let orientation = img.orientation;
        let rotation = 0;
        let cw = 0;
        let ch = 0;
        let cx = 0;
        let cy = 0;

        switch (orientation) {

            case 6:
                cw = img.height;
                ch = img.width;
                cy = img.height * (-1);
                rotation = 90;
                break;
            case 3:
                cx = img.width * (-1);
                cy = img.height * (-1);
                rotation = 180;
                break;
            case 8:
                cw = img.height;
                ch = img.width;
                cx = img.width * (-1);
                rotation = 270;
                break;
            case 1:
            default:
                cx = 0;
                cy = 0;
                cw = img.width;
                ch = img.height;
                break;

        }

        let imgratio = cw / ch;
        //let hor = true;
        let scale = desiredW / cw;


        if (cw < ch) {
            imgratio = 1 / imgratio;
            scale = desiredH / ch;
            //hor = false;
        }

        if (scale < 1) {
            if (cw >= ch) {
                c.setAttribute("width", desiredW);
                c.setAttribute("height", desiredW / imgratio);
            } else {
                c.setAttribute("width", cw * scale);
                c.setAttribute("height", desiredH);
            }

            ctx.scale(scale, scale);
        } else {
            c.setAttribute("width", cw);
            c.setAttribute("height", ch);
        }

        if (rotation > 0) {
            c.rotate(rotation * Math.PI / 180);
        }

        ctx.drawImage(img, cx, cy);

        return {
            image64: c.toDataURL("image/jpeg", 0.7),
            dimension: function () {
                if (orientation === 1)
                    return c.width;

                return c.height;
            }
        }
    },
    extendedMethods() {
        String.prototype.hashCode = function () {
            let hash = 0;
            for (let i = 0; i < this.length; i++) {
                let character = this.charCodeAt(i);
                hash = ((hash << 5) - hash) + character;
                hash = hash & hash;
            }
            return hash;
        },
        Date.prototype.addHours = function (h) {
            this.setTime(this.getTime() + (h * 60 * 60 * 1000));
            return this;
        },
        Date.prototype.addMinutes = function (m) {
            this.setTime(this.getTime() + (m * 60 * 1000));
            return this;
        },
        String.prototype.isJSON = function(s){
            try {
                return (JSON.parse(s) && !!s);
            } catch (e) {
                return false;
            }
        }
    },
    langCalendar() {
        return {
            days: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            months: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
        }
    },
    getRandom() {
        return new Date().getTime() + Math.floor(Math.random() * 900001);
    },
    arrayRemove(array, itemToRemove) {
        return array.filter(function (item) {
            return item != itemToRemove;
        });
    },
    parseHTML(s) {
        let parser = new DOMParser;
        if (parser)
            return parser.parseFromString(s, 'text/html').body.textContent;
        return s;
    },
    isChild(parent, child) {
        let node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    },
    isCollision(obj1, obj2) {
        if (!obj1 || !obj2) return;

        function outerHeight(el) {
            let height = el.offsetHeight;
            let style = getComputedStyle(el);
            height += parseInt(style.marginTop) + parseInt(style.marginBottom);
            return height;
        }

        function outerWidth(el) {
            let width = el.offsetWidth;
            let style = getComputedStyle(el);
            width += parseInt(style.marginLeft) + parseInt(style.marginRight);
            return width;
        }

        let x1 = obj1.offsetLeft;
        let y1 = obj1.offsetTop;
        let h1 = outerHeight(obj1);
        let w1 = outerWidth(obj1);
        let b1 = y1 + h1;
        let r1 = x1 + w1;
        let x2 = obj2.offsetLeft;
        let y2 = obj2.offsetTop;
        let h2 = outerHeight(obj1);
        let w2 = outerWidth(obj2);
        let b2 = y2 + h2;
        let r2 = x2 + w2;

        if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;
        return true;
    },
    getSelectValue(id) {
        let obj = document.getElementById(id);
        if (!obj) {
            return null;
        }
        return obj.options[obj.selectedIndex].value;
    },
    isDefined(object) {
        return object !== null && object !== 'undefined' && object;
    },
    /*toDateISOFormat(y, m) {
        return new Date(Number(y), Number(m) - 1, 1, 13, 0, 0, 0).toISOString();
    },*/
    toDateISOFormat(p1, p2) {
        if (!p2) {
            if (!p1) {
                return;
            }
            let arr = p1.split("/");
            let d = new Date(arr[2] + "-" + arr[1] + "-" + arr[0]);
            return d.toISOString();
        } else {
            return new Date(Number(p1), Number(p2) - 1, 1, 13, 0, 0, 0).toISOString();
        }
    },
    stringToBool(v) {
        if (typeof v === 'string') {
            return JSON.parse(v);
        }
    },
    sortCompare(a, b) {
        return Number(a.order) - Number(b.order);
    },
    mapObject(object, to) {
        for (let k in object) {
            if (Object.prototype.hasOwnProperty.call(object, k)) {
                to[k] = object[k];
            } else {
                console.log('unknown: ' + k);
            }
        }
    }
}