import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        /*siteid: null,
        editionid: null,
        entityid:null,*/
        snackbarState: {
            visible: false,
            success: false,
            message: null
        },
        error: false,
        dto: {},
        submitted: false,
        isFormChanged: false,
        showModal: false,
        toRouteName: null,
        userName: null,
        counters: {
            set: false,
            Operators: 0,
        },
        cacheWebsite: [],
        cacheEdition: [],
        externalLinks: [],
        mainTitle: null
    },
    getters: {
        /*getSiteid: state => {
            return state.siteid;
        },
        getEditionid: state => {
            return state.editionid;
        },
        getEntityid: state => {
            return state.entityid;
        },*/
        snackbar: state => {
            return state.snackbarState
        },
        dto: state => {
            return state.dto;
        },
        submitted: state => {
            return state.submitted;
        },
        isFormChanged: state => {
            return state.isFormChanged;
        },
        showModal: state => {
            return state.showModal;
        },
        toRouteName: state => {
            return state.toRouteName;
        },
        error: state => {
            return state.error;
        },
        userName: state => {
            return state.userName;
        },
        counters: state => {
            return state.counters;
        },
        cacheWebsite: state => {
            return state.cacheWebsite;
        },
        cacheEdition: state => {
            return state.cacheEdition;
        },
        externalLinks: state => {
            return state.externalLinks;
        },
        mainTitle: state => {
            return state.mainTitle;
        },
    },
    mutations: {
        /*setSiteid(state, id) {
            state.siteid = id
        },
        setEditionid(state, id) {
            state.editionid = id
        },*/
        setSnackbarState(state, result) {
            state.snackbarState.success = result.success;
            state.snackbarState.visible = result.visible;
            state.snackbarState.message = result.message;
        },
        setDto(state, dto) {
            state.dto = dto
        },
        setSubmitted(state, submit) {
            state.submitted = submit;
        },
        setFormChanged(state, changed) {
            state.isFormChanged = changed;
        },
        setShowModal(state, show) {
            state.showModal = show;
        },
        setToRouteName(state, name) {
            state.toRouteName = name;
        },
        setError(state, isError) {
            state.error = isError;
        },
        setUserName(state, name) {
            state.userName = name;
        },
        setCounters(state, dto) {
            for (let i in dto) {
                state.counters[i] = dto[i];
            }
            state.counters.set = true;
        },
        setWebsiteCache(state, dto) {
            if (!dto.items) {
                state.cacheWebsite = state.cacheWebsite.filter((item) => {
                    return item.websiteid != dto.websiteid && item.code != dto.code
                });
            } else {
                state.cacheWebsite.push(dto);
            }
        },
        setEditionCache(state, dto) {
            state.cacheEdition.push(dto);
        },
        setExternalLinks(state, dto) {
            state.externalLinks.push(dto);
        },
        clearExternalLinks(state) {
            state.externalLinks = [];
        },
        setMainTitle(state, value) {
            state.mainTitle = value;
        },
    }
});