export default {
    isAuth() {
        return !!localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE);
    },
    get(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    set(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    },
    clear(key) {
        localStorage.removeItem(key);
    }
}