import axios from 'axios';
import router from "@/router";
//import NProgress from 'nprogress'

export default {
    post(url, data, withCredentials = false) {
        let promise = new Promise(function (resolve) {

            let client = axios.create({
                baseURL: process.env.VUE_APP_APICLIENT_BASEURL,
                headers: {
                   // Accept: 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
            });

            if (withCredentials) {
                let storage = process.env.VUE_APP_LOCALSTORAGE;
                let validJson = false;
                try {
                    validJson = !!JSON.parse(localStorage.getItem(storage));
                } catch {
                    validJson = false
                }
                let token;
                if (localStorage.getItem(storage) != 'undefined' &&
                    validJson &&
                    JSON.parse(localStorage.getItem(storage))) {
                    token = JSON.parse(localStorage.getItem(storage));
                }
                if (token) {
                    client.interceptors.request.use(config => {
                        config.headers.Authorization = `Bearer ${token}`;
                        return config
                    })
                }
            }

            /*if (showProgress) {
                client.interceptors.request.use(config => { // Called on request
                    //NProgress.start()
                    return config
                });
                client.interceptors.response.use(response => { // Called on response
                    //NProgress.done()
                    return response
                });
            }*/

            client.interceptors.response.use(
                response => response,
                error => {
                    //NProgress.done();

                    if (error.response.status === 401) {
                        //router.app.Tools.LocalStorage.clear(process.env.VUE_APP_LOCALSTORAGE);
                        router.push({name: 'login'});
                    } /*else if (error.response.status === 500) {
                        router.push({name: 'ErrorPage'});
                    }*/

                    return Promise.reject(error);
                });
            try {
                client.post(url, data).then((response) => {
                    /*if (!response.data || !response.data.success) {
                        console.error(response);
                    }*/
                    resolve(response.data);
                });
            }catch{
               console.log('err')
            }


        });

        return promise;
    }
};
