import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#07131f',
                //'pink--text': '#e23854',
                success: '#b2cb1b',
                info: '#b1b1b1',
                error: '#e00025',
                bgmoto:'#ffdf00',
                motoblue:'#07131f',
                motoyellow:'#ffdf00',
                bgadmin:'#f3f4f7'
            }
        },
    }
});
