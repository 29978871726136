<template>
  <v-app :style="{background: $vuetify.theme.themes['light'].bgadmin}">

    <!--toolbar-->
    <v-app-bar app color="motoblue" fixed clipped-left clipped-right flat>
      <!--hamburger-->
      <v-app-bar-nav-icon @click="drawer = !drawer" style="background-color: #ffdf00"></v-app-bar-nav-icon>
      <!--hamburger.-->

      <!--logo-->
      <v-toolbar-title>
        <v-list color="transparent" class="ma-0 pa-0" style="width: 290px">
          <v-list-item style="height: 40px" class="pa-0">
            <v-list-item-content class="pa-0 ml-4" style="max-width: 50px">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" viewBox="-39.346 167.094 634.626 507.702">
                <path fill="#FFF"
                      d="M-36.808 660.834l-2.538 13.962h145.963l8.885-63.463-147.233 19.039zM-19.039 552.947l140.888 19.039 10.153-71.078-144.694 16.5z"/>
                <path fill="#FFF"
                      d="M378.238 167.094L277.967 372.713l-45.693-104.078L25.385 294.02l-7.615 45.693 260.197 33L6.346 405.714 0 446.33l137.079 17.77 7.616-49.501 27.923 54.578 105.349 12.692-92.656 11.424 46.963 92.655 45.693 6.347-39.348 5.076 39.348 77.425 137.079-260.197 33 260.197H595.28l-76.155-507.702z"/>
                <path fill="#FFF" d="M186.58 167.094H46.962l-2.538 15.231-8.885 49.501 190.388 25.385z"/>
              </svg>
            </v-list-item-content>
            <v-list-item-content class="pa-0 ml-3" style="max-width:198px">
              <svg xmlns="http://www.w3.org/2000/svg" width="198" height="40" viewBox="0 360.816 595.279 120.258">
                <path fill="#FCFCFC" d="M455.78 410.422l-6.013 17.438h11.725z"/>
                <path fill="#FCFCFC"
                      d="M0 360.816v120.258h595.28V360.816H0zm82.678 44.796H65.541v9.019h15.634v12.026H65.541v21.346H51.41v-54.116h31.268v11.725zm63.437 0h-16.837v9.019h15.935v12.026h-15.935v9.32h16.837v12.025h-30.967v-54.116h30.967v11.726zm65.24 38.784a23.604 23.604 0 01-15.333 5.111 34.222 34.222 0 01-19.241-6.313l6.013-11.425a20.387 20.387 0 0012.627 5.712c3.308 0 6.915-1.804 6.915-5.411 0-3.608-5.412-5.412-8.719-6.313-9.02-2.405-14.731-4.811-14.731-15.333-.434-9.704 7.082-17.921 16.786-18.354a17.919 17.919 0 011.854.015 38.308 38.308 0 0117.137 4.51l-5.712 10.823a15.696 15.696 0 00-9.32-3.608c-2.706 0-6.013 1.503-6.013 4.51s3.908 4.51 6.313 5.111l3.607 1.203c7.517 2.104 13.529 6.013 13.529 14.732a18.884 18.884 0 01-5.712 15.03zm70.351-38.784H269.98v42.391h-14.13v-42.391h-11.725v-12.026h37.581v12.026zm43.593 42.391h-14.13v-54.116h14.13v54.116zm61.934 0H376.41l-22.85-54.116h15.333l13.229 33.071 13.229-33.071h15.333l-23.451 54.116zm81.775 0l-3.607-9.319h-19.542l-3.607 9.319H427.22l21.045-54.417h15.333l20.444 54.417h-15.034zm75.162 0h-30.967v-54.116h14.131v42.392h16.836v11.724z"/>
              </svg>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar-title>
      <!--logo.-->

      <v-spacer></v-spacer>

      <!--filters-->
      <div class="d-flex flex-nowrap" style="max-width: 400px">
        <v-select background-color="white" class="mt-6 mr-4 flex-grow-0" dense :full-width="false" outlined
                  :items="sites" item-value="ID" item-text="Name" solo
                  v-model="selectedSite" return-object @change="getEdition"></v-select>

        <v-select background-color="white" class="mt-6 flex-grow-0" dense :full-width="false" outlined
                  :items="editions" item-value="ID" item-text="Name" solo
                  v-model="selectedEdition" return-object @change="setRoute"></v-select>
      </div>
      <!--filters.-->

    </v-app-bar>
    <!--toolbar.-->

    <!--nav-->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <div class="pa-4 pt-16 motoyellow">
        <div class="black--text">{{ getFullName }}</div>
      </div>
      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in draweritems" :key="item.title" link @click="toRoute(item)" class="mt-2">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!--nav.-->

    <!--content-->
    <v-main class="lighten-4 pb-16 _bg-gray">
      <v-container style="max-width: 1550px !important;">
        <div class="_btnadd pa-0" v-if="isAddVisible">
          <v-btn rounded color="motoyellow" @click="add" style="height: 50px">
            <v-icon class="mr-1">mdi-plus</v-icon>
            <div class="mt-1">Aggiungi</div>
          </v-btn>
        </div>

        <div class="d-flex flex-column" style="position:fixed;margin-left: 20px;top: 143px;"
             v-if="$store.getters.externalLinks.length>0 && $router.currentRoute.name.indexOf('categorycontents')===-1 && $router.currentRoute.name.indexOf('content')!=-1">
          <div class="pa-0 mt-4" v-for="(item,index) in $store.getters.externalLinks" :key="index">
            <v-btn width="185px" small rounded color="green darken-3 white--text" @click="openWindow(item.URL)"
                   class="_mt0" style="height: 40px">
              <div class="mt-1">{{ item.Label }}</div>
              <v-icon small class="ml-1">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
      <v-container class="pb-2" v-if="$router.currentRoute.name!='dashboard'" style="margin-top: -24px">
        <v-row no-gutters>
          <v-col class="mt-1" cols="12">
            <!--<v-breadcrumbs :items="breadCrumbsItems" class="pt-2" :key="refresh"></v-breadcrumbs>-->
            <v-col class="mt-2 pa-0" cols="12">
              <v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="10" class="pa-0">
                    <div class="d-flex flex-row flex-nowrap">
                      <v-btn icon class="mr-10" @click="back">
                        <v-tooltip top :transition="'none'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-arrow-left</v-icon>
                          </template>
                          torna alla dashboard
                        </v-tooltip>
                      </v-btn>
                      <h3 class="text-left"
                          style="height: 36px;line-height: 36px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                        {{ getTitle }}</h3>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
      <transition name="fade" mode="out-in">
        <router-view ref="form" :class="shake"></router-view>
      </transition>

      <v-btn v-scroll="onScroll" v-show="showToTop" fab dark fixed bottom right color="#ffdf00" @click="toTop">
        <v-icon color="primary">mdi-chevron-up</v-icon>
      </v-btn>
    </v-main>
    <!--content.-->

    <!--footer-->
    <!--<v-footer style="background-color:#e8e9ed">
      <v-container>
        <v-row>
          <v-col class="text-center pt-16 pb-16" cols="12">
            &nbsp;
          </v-col>
        </v-row>
      </v-container>
    </v-footer>-->
    <!--footer.-->

    <!--modals-->
    <v-dialog v-model="showModal" max-width="590" @click:outside="abort">
      <v-card rounded="3" class="pa-1">
        <v-card-title class="headline">Attenzione</v-card-title>
        <v-card-text><b>Non hai salvato i dati modificati.</b><br/><br>
          Premi
          <v-btn x-small outlined @click="saveForm">Salva</v-btn>
          per salvare e procedere,
          <v-btn x-small outlined @click="proceed">Non salvare</v-btn>
          per continuare senza salvare,
          <v-btn x-small outlined @click="abort">Annulla</v-btn>
          per rimanere nella scheda corrente.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="bgmoto" @click="saveForm" width="150px" class="mr-3">
            Salva
          </v-btn>
          <v-btn elevation="0" @click="proceed" class="mr-3">
            Non salvare
          </v-btn>
          <v-btn color="green darken-1" text @click="abort">
            Annulla
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--<v-row justify="center">-->
    <v-dialog v-model="dialog.visible" max-width="560">
      <v-card rounded="3" class="pa-1">
        <v-card-title class="headline">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text>{{ dialog.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click.stop="abortEventDialog">
            Annulla
          </v-btn>
          <v-btn elevation="0" color="bgmoto" @click.stop="confirmEventDialog">
            {{ dialog.labelCallToAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--</v-row>-->
    <!--modal.-->

    <!--toast-->
    <v-snackbar left transition="slide-x-transition" color="black" v-model="$store.getters.snackbar.visible"
                :timeout="snackbar.timeout">
      <v-icon color="green" class="mr-2" v-if="$store.getters.snackbar.success">mdi-check</v-icon>
      <v-icon color="red" class="mr-2" v-else>mdi-alert-circle-outline</v-icon>
      <span class="white--text">{{ getSnackbarMessage }}</span>
      <!--<template v-slot:action="{ attrs }">
        <v-btn text plain color="black" @click="closeSnackbar" v-bind="attrs">
          Chiudi
        </v-btn>
      </template>-->
    </v-snackbar>
    <!--toast.-->
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showToTop: false,
      showModal: false,
      drawer: null,
      isAddVisible: false,
      dialog: {
        visible: false,
        title: null,
        message: null,
        labelCallToAction: 'Salva'
      },
      snackbar: {
        timeout: 3000,
      },
      breadCrumbsItems: [],
      draweritems: [],
      fixeddraweritems: [
        {
          title: 'Sponsor',
          icon: 'mdi-watermark',
          routename: 'sponsor',
          hasparams: true
        },
        {
          title: 'Calendario',
          icon: 'mdi-calendar',
          routename: 'calendar',
          hasparams: true
        },
        {
          title: 'Categorie e Tags',
          icon: 'mdi-tag',
          routename: 'category',
          hasparams: true
        },
        {
          title: 'Contenuti',
          icon: 'mdi-text-box',
          routename: 'contentlist',
          hasparams: true
        },
        {
          title: 'Video',
          icon: 'mdi-video-vintage',
          routename: 'videolist',
          hasparams: true
        },
        {
          title: 'Configurazioni',
          icon: 'mdi-code-json',
          routename: 'config',
          hasparams: true
        },
        {
          title: 'Commenti',
          icon: 'mdi-comment-text',
          routename: 'commentlist',
          hasparams: true
        },
        {
          title: 'Statistiche',
          icon: 'mdi-chart-box',
          routename: 'stats',
          hasparams: true
        },
      ],
      sites: [],
      editions: [],
      selectedSite: null,
      selectedEdition: null,
      nopHandler: null,
      title: null
    }
  },
  created() {
    this.setAddVisibility();
    this.title = this.$router.currentRoute.meta.pageTitle;

    this.nopHandler = () => {
    }
    this.$root.$on('abortEventDelete', this.nopHandler);

    if (!this.$store.getters.userName) {
      this.$store.commit('setUserName', `${this.Tools.operator.getUser().FirstName} ${this.Tools.operator.getUser().LastName}`);
    }

    this.Tools.api.post("/website/list", null, true).then(
        result => {
          if (result.Success) {
            this.sites = result.Data.Items;
            if (this.$route.params.websiteid) {
              this.selectedSite = this.sites.filter((item) => {
                return item.ID === Number(this.$route.params.websiteid)
              })[0];
            } else {
              this.selectedSite = this.sites[0];
            }
            this.getEdition().then(() => {
              this.getLinks().then(() => {
                this.isLoading = false;
              });
            });
          }
        })
  },
  mounted() {
    this.$root.$on('toTop', () => {
      this.toTop();
    })

    this.$root.$on('openDialog', (payload) => {
      this.Tools.dom.mapObject(payload, this.dialog)
      this.dialog.visible = !this.dialog.visible;
    })
  },
  methods: {
    getLinks() {
      return this.Tools.api.post("/dashboard/get/links/" + this.$router.currentRoute.params.websiteid + "/" + this.$router.currentRoute.params.editionid, null, true).then(
          result => {
            let links = result.Data.Links;

            this.draweritems.push({
              title: 'Dashboard',
              icon: 'mdi-view-dashboard',
              routename: 'dashboard'
            });

            for (let l of links) {
              l.Path = JSON.parse(l.Path);

              if (l.Path.routename) {
                this.draweritems.push({
                  customlink: true,
                  title: l.Title,
                  icon: 'mdi-home',
                  routename: l.Path.routename,
                  hasparams: true,
                  params: {
                    websiteid: this.$router.currentRoute.params.websiteid,
                    editionid: this.$router.currentRoute.params.editionid,
                    id: l.ID
                  }
                });
              } else {
                this.draweritems.push({
                  title: l.Title,
                  icon: 'mdi-open-in-new',
                  url: l.Path.url,
                  hasparams: true
                });
              }
            }

            for (let l of this.fixeddraweritems) {
              this.draweritems.push(l);
            }

            if (this.Tools.operator.getUser().IsAdmin) {
              this.draweritems.push({
                title: 'Operatori',
                icon: 'mdi-account-multiple',
                routename: 'operatorlist',
                hasparams: true
              })
            }
            this.draweritems.push({
              title: 'Cambia password',
              icon: 'mdi-lock',
              routename: 'operatorchangepassword',
              hasparams: true
            });
            this.draweritems.push({
              title: 'Logout',
              icon: 'mdi-logout',
              routename: 'login',
              logout: true
            });
          });
    },
    back() {
      this.$router.push({path: '/app/dashboard/' + this.selectedSite.ID + '/' + this.selectedEdition.ID});
    },
    add() {
      this.$refs.form.$children[0].toNew();
    },
    setAddVisibility() {
      let v = this.$router.currentRoute.name === 'category' ||
          this.$router.currentRoute.name === 'tags' ||
          this.$router.currentRoute.name === 'contents' ||
          this.$router.currentRoute.name === 'categorycontents' ||
          this.$router.currentRoute.name === 'calendar' ||
          this.$router.currentRoute.name === 'sponsor' ||
          this.$router.currentRoute.name === 'operatorlist' ||
          this.$router.currentRoute.name === 'contentlist' ||
          this.$router.currentRoute.name === 'videolist' ||
          this.$router.currentRoute.name === 'config';
      if (v) {
        let t = setTimeout(() => {
          clearTimeout(t);
          this.isAddVisible = true;
        }, 300);
      } else {
        this.isAddVisible = false;
      }
    },
    openWindow(url) {
      window.open(url, "_blank");
    },
    toRoute(item) {
      if (this.$router.currentRoute.name === item.routename) {
        this.drawer = false;
        return
      }

      if (item.logout) {
        this.Tools.localStorage.clear(process.env.VUE_APP_LOCALSTORAGE);
        window.location = "/"
      } else {
        if (!item.hasparams) {
          this.$router.push({name: item.routename})
        } else if (item.url) {
          window.open(item.url, "_blank");
        } else if (item.customlink) {
          this.$router.push({
            name: item.routename,
            params: item.params
          })
        } else {
          this.$router.push({
            name: item.routename,
            params: {
              websiteid: this.$router.currentRoute.params.websiteid,
              editionid: this.$router.currentRoute.params.editionid
            }
          })
        }
      }

    },
    setRoute() {
      //this.$store.commit('setSiteid', this.selectedSite.ID);
      //this.$store.commit('setEditionid', this.selectedEdition.ID);
      if (this.$router.currentRoute.params.websiteid && (Number(this.$router.currentRoute.params.websiteid) != Number(this.selectedSite.ID) ||
          Number(this.$router.currentRoute.params.editionid) != Number(this.selectedEdition.ID))) {

        this.$router.push({
          name: 'dashboard',
          params: {
            websiteid: this.selectedSite.ID,
            editionid: this.selectedEdition.ID
          }
        });
      }
    },
    setEdition() {
      this.$router.push({
        name: 'dashboard',
        params: {websiteid: this.selectedSite.ID, editionid: this.selectedEdition.ID}
      });
    },
    /*setBreadCrumbs() {
      this.breadCrumbsItems = [{
        text: 'Dashboard',
        disabled: false,
        to: {
          path: '/app/dashboard/' + this.selectedSite.ID + '/' + this.selectedEdition.ID
        }
      }];

      if (this.$route.meta.breadCrumbsItems) {
        for (let b of this.$route.meta.breadCrumbsItems) {
          if (b.to && !b.avoidAppend) {
            if (this.$router.currentRoute.name.indexOf('operator') != -1) {
              b.to.path = b.to.originalPath;
              b.to.path = b.to.path + "/" + this.$router.currentRoute.params.operatorid;
            } else {
              b.to.path = b.to.path + "/" + this.selectedSite.ID + "/" + this.selectedEdition.ID;
              b.avoidAppend = true;
            }

          }
          this.breadCrumbsItems.push(b);
        }
      }

      this.refresh++;
    },*/
    saveForm() {
      this.$refs.form.$children[0].save().then((error) => {
        if (error) {
          this.$store.commit('setShowModal', false);
        } else {
          this.proceed();
        }
      })
    },
    proceed() {
      this.$store.commit('setShowModal', false);
      this.$store.commit('setFormChanged', false);
      this.$router.push({name: this.$store.getters.toRouteName});
    },
    abort() {
      this.$store.commit('setShowModal', false);
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.showToTop = top > 200
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    closeSnackbar() {
      let payload = {
        success: true,
        visible: false,
        code: null
      }
      this.$store.commit('setSnackbarState', payload)
    },
    abortEventDialog() {
      this.dialog.visible = false;
      if (this.dialog.abortEventName) {
        this.$root.$emit(this.dialog.abortEventName, {id: this.dialog.id});
      }
    },
    confirmEventDialog() {
      this.dialog.visible = false;
      this.$root.$emit(this.dialog.confirmEventName, {id: this.dialog.id});
    },
    getEdition() {
      return this.Tools.api.post("/edition/list/" + this.selectedSite.ID, null, true).then(
          result => {
            this.editions = result.Data.Items
            this.selectedEdition = this.editions[0];
            this.setRoute();
          })
    }
  }
  ,
  computed: {
    getTitle() {
      let title = this.$store.getters.mainTitle ? this.$store.getters.mainTitle : this.title
      if (this.$store.getters.isFormChanged) {
        return title = title + "*";
      }
      return title;
    }
    ,
    getFullName() {
      return this.$store.getters.userName;
    }
    ,
    getSnackbarMessage() {
      return this.$store.getters.snackbar.message;
    }
    ,
    shake() {
      let snackbar = this.$store.getters.snackbar;
      return {
        'animate__shakeX': snackbar.visible && !snackbar.success
      }
    }
  }
  ,
  watch: {
    '$store.getters.showModal'() {
      this.showModal = this.$store.getters.showModal;
    }
    ,
    $route() {
      this.showModal = this.dialog.visible = false;
      this.setAddVisibility();
      this.title = this.$router.currentRoute.meta.pageTitle;
      if (!(this.$router.currentRoute.name.indexOf('categorycontents') === -1 && this.$router.currentRoute.name.indexOf('content') != -1)) {
        this.$store.commit('setMainTitle', null);
      }
    }
    ,
  }
}
</script>

<style scoped>
.v-app-bar__nav-icon.v-btn.v-btn--round {
  border-radius: 0;
}

.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
  height: 64px;
  width: 64px;

}

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child {
  margin-left: -18px;
}


</style>