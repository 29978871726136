<template>
  <v-app>
    <v-main v-if="!isLoading">
      <v-container fluid class="pa-0" style="height: 100%;max-width: 100%!important;">


        <v-row :class="flexDisposition" no-gutters style="width:100%;height: 100%">

          <v-col md="6" lg="6" :align="getAlignForm" align-self="center" class="pr-9">
            <v-row :no-gutters="isSmallAndDown" class="ma-0 justify-center">
              <v-col cols="auto">
                <h1 class="text-center">BKO Video Festival</h1>
              </v-col>
            </v-row>

            <v-row :no-gutters="isSmallAndDown" style="width: 500px" class="ma-0">
              <v-col cols="auto" class="pl-0">
                <transition name="fade" mode="out-in">
                  <h2 :key="page.title">Login</h2>
                </transition>
              </v-col>
            </v-row>


            <transition name="slide" mode="out-in">
              <!--<router-view name="Form" ref="form"></router-view>-->
              <router-view ref="form"></router-view>
            </transition>

          </v-col>

          <v-col cols="auto" class="hidden-sm-and-down">
            <v-divider vertical></v-divider>
          </v-col>

          <v-col md="6" lg="6" style="height:100%;background-color: #07131f;align-items: center" class="d-flex pl-9">

            <div class="d-flex" style="flex-direction: column">
              <figure class="mfoot-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="601.327" height="151.182"
                     viewBox="-6.048 345.354 601.327 151.182">
                  <path fill="#FFF"
                        d="M-5.292 492.379l-.756 4.157h43.465l2.647-18.897-43.844 5.669zM261.167 345.354c-43.087 0-78.236 32.882-78.236 72.945 0 45.355 32.882 78.237 78.236 78.237s78.236-32.882 78.236-78.237c.001-40.063-35.149-72.945-78.236-72.945zm0 111.119c-20.031 0-35.527-16.63-35.527-37.795 0-17.764 16.63-33.26 35.527-33.26s35.528 15.497 35.528 33.26c.962 19.89-14.383 36.794-34.273 37.757a40.35 40.35 0 01-1.255.038zM440.697 345.354H337.514v36.662h30.614v114.52h42.331v-114.52h30.238zM517.043 345.354c-43.087 0-78.237 32.882-78.237 72.945 0 45.355 32.883 78.237 78.237 78.237s78.236-32.882 78.236-78.237c.001-40.063-35.149-72.945-78.236-72.945zm0 111.119c-20.031 0-35.528-16.63-35.528-37.795 0-17.764 16.631-33.26 35.528-33.26s35.527 15.497 35.527 33.26c.001 21.165-15.496 37.795-35.527 37.795zM0 460.253l41.953 5.669 3.024-21.166L1.89 449.67z"></path>
                  <path fill="#FFF"
                        d="M118.3 345.354l-29.858 61.229-13.607-30.993-61.606 7.559-2.268 13.607 77.481 9.827-80.883 9.827-1.89 12.094 40.82 5.292 2.267-14.741 8.316 16.253 31.37 3.779-27.591 3.401 13.984 27.591 13.607 1.89-11.717 1.511 11.717 23.056 40.819-77.481 9.827 77.481h43.843l-22.678-151.182z"></path>
                  <path fill="#FFF" d="M61.229 345.354H19.654l-.756 4.536-2.646 14.74 56.694 7.559z"></path>
                </svg>
              </figure>
              <span class="mfoot-logo-claim">Guida la passione</span>
            </div>
          </v-col>

        </v-row>

      </v-container>

      <v-snackbar left transition="slide-x-transition" color="black" v-model="$store.getters.snackbar.visible" :timeout="snackbar.timeout">
        <v-icon color="red" class="mr-2">mdi-alert-circle-outline</v-icon>
        <span class="white--text">{{ getSnackbarMessage }}</span>
      </v-snackbar>

    </v-main>


  </v-app>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,

      snackbar: {
        //visible: false,
        timeout: 2000,
        //text: null,
      },
      page: {
        title: null,
        labelLink: null,
        paragraph: null,
        route: {path: null}
      }
    }
  },
  methods: {
    init(){
      this.isLoading = !this.$route.name;
    },
  },
  computed: {
    getSnackbarMessage() {
      return this.$store.getters.snackbar.message;
    },
    isSmallAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    flexDisposition() {
      return this.$vuetify.breakpoint.smAndDown ? 'flex-column' : ''
    },
    getRoute() {
      return this.page.route
    },
    getAlignForm() {
      return !this.$vuetify.breakpoint.smAndDown ? 'right' : 'center'
    },
  },
  watch: {
    $route() {
      this.init();
    }
  }
}
</script>
<style scoped>
h1 {
  font-weight: 900;
  font-size: 3rem;
  margin-bottom: 50px;
  margin-top: -150px;
}

.mfoot-logo-claim {
  display: inline-block;
  color: #ffdf00;
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 900;
  text-transform: uppercase;
}

figure svg {
  width: 220px;
  height: 64px;
}
</style>