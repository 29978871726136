import Vue from 'vue'
import VueRouter from 'vue-router'
import user from '../services/account/user'

Vue.use(VueRouter)

const routes = [
    /*{
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ //'../views/About.vue')
    //}
    {
        path: '/',
        redirect: {
            name: 'login'
        }
    },
    {
        path: '/app',
        component: () => import("../views/wrap"),
        children: [
            {
                path: 'login',
                name: 'login',
                meta: {
                    pageTitle: "Login",
                    requiresAuth: false,
                    layout: "account"
                },
                component: () => import('../views/sections/login/form')
                /*components: {
                    Form: () => import('../views/sections/login/form')
                }*/
            },
            {
                path: 'noroles',
                name: 'noroles',
                meta: {
                    pageTitle: "No Roles",
                    requiresAuth: false,
                    layout: "account"
                },
                component: () => import('../views/sections/operator/noroles')
            },
            /*{
                path: 'dashboard',
                name: 'dashboard',
                meta: {
                    pageTitle: "Dashboard",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/dashboards/operator')
            },*/
            {
                path: 'dashboard/:websiteid/:editionid',
                name: 'dashboard',
                meta: {
                    pageTitle: "Dashboard",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/dashboard/dashboard')
            },
            {
                path: 'operator/:websiteid/:editionid',
                name: 'operatorlist',
                meta: {
                    keyPageSetup: 'operator',
                    pageTitle: "Operatori",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/operator/operator')
            },
            {
                path: 'operator/:websiteid/:editionid/:operatorid',
                name: 'operatorform',
                meta: {
                    keyPageSetup: 'operatorform',
                    pageTitle: "Operatori",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/operator/operator')
            },
            {
                path: 'operator/:websiteid/:editionid/:operatorid/roles',
                name: 'operatorroles',
                meta: {
                    keyPageSetup: 'operatorroles',
                    pageTitle: "Operatori",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/operator/operator')
            },
            {
                path: 'operator/changepassword/:websiteid/:editionid',
                name: 'operatorchangepassword',
                meta: {
                    pageTitle: "Cambio password",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/operator/changepassword')
            },
            {
                path: 'homepage/:websiteid/:editionid/:id',
                name: 'homepage',
                meta: {
                    pageTitle: "Home Page",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/homepage/homepage')
            },
            /*{
                path: 'homepage/:websiteid/:editionid/:id/advanced',
                name: 'homepageadvanced',
                meta: {
                    pageTitle: "Home Page",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/homepage/homepage')
            },*/
            {
                path: 'sponsor/:websiteid/:editionid',
                name: 'sponsor',
                meta: {
                    keyPageSetup: 'sponsor',
                    pageTitle: "Sponsor",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/sponsor/sponsor')
            },
            {
                path: 'sponsor/:websiteid/:editionid/:sponsorid',
                name: 'sponsorform',
                meta: {
                    keyPageSetup: 'sponsorform',
                    pageTitle: "Sponsor",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/sponsor/sponsor')
            },
            {
                path: 'config/:websiteid/:editionid',
                name: 'config',
                meta: {
                    keyPageSetup: 'config',
                    pageTitle: "Configurazioni",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/config/config')
            },
            {
                path: 'config/:websiteid/:editionid/:configid',
                name: 'configform',
                meta: {
                    keyPageSetup: 'configform',
                    pageTitle: "Configurazioni",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/config/config')
            },
            {
                path: 'calendar/:websiteid/:editionid',
                name: 'calendar',
                meta: {
                    pageTitle: "Calendario",
                    keyPageSetup: 'calendar',
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/calendar/calendar')
            },
            {
                path: 'calendar/:websiteid/:editionid/:calendarid',
                name: 'calendarform',
                meta: {
                    pageTitle: "Calendario",
                    keyPageSetup: 'calendarform',
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/calendar/calendar')
            },
            {
                path: 'category/:websiteid/:editionid',
                name: 'category',
                meta: {
                    keyPageSetup: 'categories',
                    pageTitle: "Categorie e Tags",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/category/category')
            },
            {
                path: 'category/:websiteid/:editionid/:categoryid',
                name: 'categoryform',
                meta: {
                    keyPageSetup: 'categoryform',
                    pageTitle: "Categorie e Tags",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/category/category')
            },
            {
                path: 'category/:websiteid/:editionid/:categoryid/tag',
                name: 'tags',
                meta: {
                    keyPageSetup: 'tags',
                    pageTitle: "Categorie e Tags",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/category/category')
            },
            {
                path: 'category/:websiteid/:editionid/:categoryid/tag/:tagid',
                name: 'tagform',
                meta: {
                    keyPageSetup: 'tagform',
                    pageTitle: "Categorie e Tags",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/category/category')
            },
            {
                path: 'category/:websiteid/:editionid/:categoryid/tag/:tagid/content',
                name: 'categorycontents',
                meta: {
                    keyPageSetup: 'tagform',
                    pageTitle: "Categorie e Tags",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/category/category')
            },
            {
                path: 'content/:websiteid/:editionid',
                name: 'contentlist',
                meta: {
                    keyPageSetup: 'contentlist',
                    pageTitle: "Elenco contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid',
                name: 'contentform',
                meta: {
                    keyPageSetup: 'contentform',
                    pageTitle: "Contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid/options',
                name: 'contentoption',
                meta: {
                    keyPageSetup: 'contentoption',
                    pageTitle: "Opzioni contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid/images',
                name: 'contentimage',
                meta: {
                    keyPageSetup: 'contentimage',
                    pageTitle: "Immagini contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid/video',
                name: 'contentvideo',
                meta: {
                    keyPageSetup: 'contentvideo',
                    pageTitle: "Video contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid/text',
                name: 'contenttext',
                meta: {
                    keyPageSetup: 'contenttext',
                    pageTitle: "Testo contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'content/:websiteid/:editionid/:contentid/tags',
                name: 'contenttags',
                meta: {
                    keyPageSetup: 'contenttags',
                    pageTitle: "Tags contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            /*{
                path: 'content/:websiteid/:editionid/:contentid/related',
                name: 'contentrelated',
                meta: {
                    keyPageSetup: 'contentrelated',
                    pageTitle: "Contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },*/
            {
                path: 'content/:websiteid/:editionid/:contentid/stat',
                name: 'contentstat',
                meta: {
                    keyPageSetup: 'contentstat',
                    pageTitle: "Statistiche contenuti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/content/content')
            },
            {
                path: 'video/:websiteid/:editionid',
                name: 'videolist',
                meta: {
                    keyPageSetup: 'videolist',
                    pageTitle: "Video",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/video/video')
            },
            {
                path: 'video/:websiteid/:editionid/:videoid',
                name: 'videoform',
                meta: {
                    keyPageSetup: 'videoform',
                    pageTitle: "Video",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/video/video')
            },
            {
                path: 'comments/:websiteid/:editionid',
                name: 'commentlist',
                meta: {
                    pageTitle: "Commenti",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/comment/comment2')
            },
            {
                path: 'stats/:websiteid/:editionid',
                name: 'stats',
                meta: {
                    pageTitle: "Statistiche",
                    requiresAuth: true,
                    layout: "admin"
                },
                component: () => import('../views/sections/stats/stats')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (router.app.$store) {
        router.app.$store.commit('setToRouteName', to.name);
    }

    if (to.matched.some(i => i.meta.requiresAuth === false)) {
        next()
    } else {
        user.getData().then((result) => {
            if (result.IsAuthenticated) {
                next();
            } else {
                next({name: 'login'});
            }
        });
    }
});

router.beforeResolve((to, from, next) => {
    if (router.app.$store.getters.isFormChanged) {
        window.history.replaceState({}, from.meta.pageTitle, from.path);
        router.app.$store.commit('setShowModal', true);
        return false;
    } else {
        next();
    }
})

export default router
