import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import ApiClient from './services/networking/apiclient.js';
import Langs from './services/functions/langs.js';
import AccountLayout from './layouts/account';
import AdminLayout from './layouts/admin';
import dom from './services/functions/domutilis.js'
import {store} from './services/store/store';
import localStorage from './services/functions/localstorage.js'
import operator from '@/services/account/user'
import VueCookies from 'vue-cookies';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'vue2-datepicker/index.css';
import '@/assets/css/global.css';
import '@/assets/css/transitions.css';
import '@/assets/css/animations.css';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(CKEditor);

Vue.component('account-layout', AccountLayout);
Vue.component('admin-layout', AdminLayout);

Vue.filter('trans', (value) => {
    if (!value) return "";
    let currentLang = "it";
    let fallbackLang = "en";
    if (value[currentLang]) {
        return value[currentLang];
    }
    if (value[fallbackLang]) {
        return value[fallbackLang];
    }
    return "";
})

Vue.filter('truefalse', (value) => {
    if (!value || value === false) return "No";
    return "Si";
})

Vue.filter('separator', (value) => {
    if (value.toString().length === 1) {
        return value.toString();
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.prototype.Tools = {
    api: ApiClient,
    i18n: Langs,
    dom,
    localStorage,
    operator
}

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
    created() {
        this.Tools.dom.extendedMethods();
        window.Vue = Vue;
    }
}).$mount('#app')
