import apiClient from '../networking/apiclient.js'

let states = {
    loaded: false,
};

let data = {
    IsAuthenticated: false
};

export default {
    /*setAuthenticated() {
        states.loaded = data.IsAuthenticated = true;
    },*/
    setData(operator) {
        data = operator;
        states.loaded = data.IsAuthenticated = true;
    },
    getUser() {
        return data;
    },
    refreshData() {
        states.loaded = false;
        return this.loadData();
    },
    getData() {
        return this.loadData().then(() => {
            return data;
        });
    },
    loadData() {
        let promise = new Promise((resolve) => {

            // data already loaded
            if (states.loaded) {
                resolve();
                return;
            }

            apiClient.post("/login/isauthenticated", null, true).then(result => {
                states.loaded = true;
                if (result.Success) {
                    if (result.Data.Operator) {
                        data = result.Data.Operator;
                    }
                    data.IsAuthenticated = result.Data.IsAuthenticated;
                }
                resolve();
            });
        });
        return promise;
    }
}
