<template>
  <transition name="fade" mode="out-in">
    <component :is="layout"></component>
  </transition>
</template>

<script>
let defaultLayout = "account";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    }
  },
  updated() {
    if (this.$router.currentRoute.meta.pageTitle) {
      document.title = this.$router.currentRoute.meta.pageTitle + process.env.VUE_APP_POSTFIX_TITLE;
    }

    this.$root.$emit('checkFormChanged');
  },
  watch: {
    $route() {
      let t = setTimeout(() => {
        clearTimeout(t);
          this.$root.$emit('toTop');
      }, 300)
    }
  },
}
</script>
